import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Link } from "react-router-dom";
import { pageConstant } from "../../constants/pageConstants";

const LpHeader = () => {
  const navigation = [
    { title: "CloudPlannerとは", link: "#about" },
    { title: "実現できること", link: "#achieved" },
    { title: "機能", link: "#function" },
    { title: "料金プラン", link: "#plan" },
    { title: "導入事例", link: "#case_studies" },
  ];

  return (
    <div className="w-full py-16px">
      <nav className="w-full relative flex items-center justify-between gap-12px px-20px mx-auto">
        <div className="flex flex-wrap items-center justify-between">
          <Link className="flex items-center space-x-2 text-2xl" to={"/"}>
            <span>
              <img
                className="w-200px"
                src={`${window.location.origin}/img/CP_logo_bl.svg`}
              />
            </span>
          </Link>
        </div>

        <div className="text-center justify-end ml-auto">
          <ul className="items-center justify-end flex-1 list-none flex gap-16px">
            {navigation.map((menu, index) => (
              <li className="mr-3 hover:opacity-80" key={index}>
                <AnchorLink
                  className="inline-block px-4px py-2px no-underline rounded-md  hover:text-indigo-500 focus:text-indigo-500 focus:bg-indigo-100 focus:outline-none"
                  href={menu.link}
                >
                  {menu.title}
                </AnchorLink>
              </li>
            ))}
            <li className="bg-white py-4px px-16px rounded-md border text-c427ed1 border-c427ed1 hover:opacity-80">
              <Link to={`/${pageConstant.LOGIN}`}>ログイン</Link>
            </li>
          </ul>
        </div>
        <div className="mr-3 space-x-4 flex gap-16px">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSdLMXKyqzkXDzZxGAc7nsiwuIfMe7S3IXU1LpIHh0aBMkcIPw/viewform" target="_blink">
            <button className="bg-c427ed1 py-4px px-16px rounded-md border text-white border-c427ed1 hover:opacity-80">
              お問い合わせ
            </button>
          </a>

          {/*<button className="text-white bg-c427ed1 py-4px px-16px vertical-middle rounded-md border border-c427ed1 hover:opacity-80">
            お問い合わせ
          </button>*/}
        </div>
      </nav>
    </div>
  );
};

export default LpHeader;
