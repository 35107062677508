import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Link } from "react-router-dom";
import { pageConstant } from "../../constants/pageConstants";

const LpFooter = () => {
  const navigation = [
    { title: "CloudPlannerとは", link: "#about", isAncor: true },
    { title: "実現できること", link: "#achieved", isAncor: true },
    { title: "機能", link: "#function", isAncor: true },
    { title: "料金プラン", link: "#plan", isAncor: true },
    { title: "導入事例", link: "#case_studies", isAncor: true },
    {
      title: "プライバシーポリシー",
      link: `/${pageConstant.TERMS_OF_SERVICE}`,
      isAncor: false,
    },
    { title: "特定商取引法に基づく表記", link: `/${pageConstant.COMMERCIAL_TRANSACTION}`, isAncor: false },
  ];

  return (
    <div className="w-full bg-c1b2e48">
      <div className="items-center px-30px py-50px">
        <Link className="flex items-center justify-center space-x-2 text-2xl" to={"/"}>
          <span>
            <img
              alt="Cloud Planner"
              className="w-150px h-64px"
              src={`${window.location.origin}/img/CP_logo_wt.svg`}
            />
          </span>
        </Link>
        <div className="text-white text-center justify-end ml-auto">
          <ul className="items-center justify-center flex-1 pt-6px list-none flex gap-50px mt-50px">
            {navigation.map((menu, index) => (
              <li className="mr-3" key={index}>
                {menu.isAncor ? (
                  <AnchorLink
                    className="inline-block px-4px py-2px no-underline rounded-md  hover:text-indigo-500 focus:text-indigo-500 focus:bg-indigo-100 focus:outline-none "
                    href={menu.link}
                  >
                    {menu.title}
                  </AnchorLink>
                ) : (
                  <Link
                    className="inline-block px-4px py-2px no-underline rounded-md  hover:text-indigo-500 focus:text-indigo-500 focus:bg-indigo-100 focus:outline-none "
                    to={menu.link}
                  >
                    {menu.title}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className="text-center items-center justify-center mt-50px">
          <p className="text-11px text-c8d97a4">
          開発：補助金活用支援合同会社      販売・運営：らくだシステム株式会社
          </p>
        </div>
      </div>
      <div className="text-center py-16px bg-[#ffffff03]">
        <p className="text-11px text-c707070">
          &copy; Cloud Planner All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default LpFooter;
