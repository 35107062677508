import React, { memo } from "react";
// import { useNavigate } from "react-router-dom";
// import { DarkButton, LightButton } from "../../components/atoms";
// import { GuestLayout } from "../../components/layout";
import { LpFooter, LpHeader } from "../../components/header";

const CommercialTransaction = () => {
  // const navigate = useNavigate();

  return (
    <React.Fragment>
        <LpHeader />

        <section className="w-full overflow-x-hidden">
          <div className="w-[600px] pt-[100px] pb-[100px] m-auto">

            <h1 className="text-30px font-bold text-center mb-50px">特定商取引法に基づく表記</h1>

            <div className="mb-12px">
              <strong>代表責任者：</strong>代表取締役小野田直人
            </div>
            <div className="mb-12px">
              <strong>所在地：</strong> 東京都江東区永代２－２３－１０
            </div>
            <div className="mb-12px">
              <strong>お問い合わせ：</strong>メールにてお問い合わせください。
            </div>
            <div className="mb-12px">
              <strong>メールアドレス：</strong>cp-toiawase@rakudasystem.co.jp
            </div>
            <div className="mb-12px">
              <strong>サイトURL：</strong>https://www.cloudplanner.jp/
            </div>
            <div className="mb-12px">
              <strong>商品の販売価格：</strong>各商品ページをご参照ください。
            </div>
            <div className="mb-12px">
              <strong>商品代金以外に必要な料金：</strong>ありません
            </div>
            <div className="mb-12px">
              <strong>支払方法：</strong>クレジットカード決済
            </div>
            <div className="mb-12px">
              <strong>支払時期：</strong> 商品注文時にお支払いが確定します。
            </div>
            <div className="mb-12px">
              <strong>商品の引渡時期：</strong> クレジットカード決済後すぐにご利用可能です
            </div>
            <div className="mb-12px">
              <strong>返品・交換：</strong> サービスの性質上返品・交換はございません。
            </div>

          </div>
        </section>

        <LpFooter />

    </React.Fragment>
  );
};

export default memo(CommercialTransaction);
